import { makeAutoObservable } from 'mobx';

export class Liku {
	id = null;
	serial = null;
	uuid = null;
	volume = 0;
	motionStatus = null;
	battery = null;
	version = null;

	store;

	constructor(store, liku) {
		makeAutoObservable(this);
		this.store = store;

		this.id = liku?.id;
		this.serial = liku?.liku?.serial || liku;
		this.uuid = liku?.liku?.uuid;

		const state = JSON.parse(liku?.liku?.state);

		this.volume = state?.volume;

		// const battery = JSON.parse(state?.battery);
		this.battery = state?.battery?.soc;
		this.version = JSON.parse(liku?.liku?.version);

		// if (isNew) this.updateUserFromServer();
		//
		// 	this.saveHandler = reaction(
		// 		() => this.autoSave,
		// 		(isSave) => {
		// 			if (isSave) {
		// 				this.updateUserFromServer();
		// 				// runInAction(() => {
		// 				this.autoSave = false;
		// 				// });
		// 			}
		// 		},
		// 	);
	}

	update(json) {
		this.id = json?.id;
		this.serial = json?.liku?.serial;
		this.uuid = json?.liku?.uuid;

		const state = JSON.parse(json?.liku?.state);
		this.volume = state?.volume;

		// const battery = JSON.parse(state?.battery);
		this.battery = state?.battery?.soc;
		this.version = JSON.parse(json?.liku?.version);
	}

	setVolume(volume) {
		this.volume = volume;
	}

	setMotionStatus(status) {
		this.motionStatus = status;
	}
}
