import * as Cookie from '../../utils/Cookie';

const EXCEPTION_TOPIC = [
	'exp',
	'volume',
	'stop',
	'motion',
	'language',
	'vis/image/metaverse',
	'check',
	'aruco',
];

// const MAX_PAYLOAD_COPY_SIZE = 15;
const MAX_WAITING_TIME = 3;
const MAX_NUM_RETRY = 1;

const AckHandler = (mqttModule) => {
	const acks = new Map();
	let ackId = 0;

	const isAckExceptions = (topic) => {
		return EXCEPTION_TOPIC.find((exception) => topic.includes(exception));
	};

	const generateAckId = (topic, payload) => {
		if (!isAckExceptions(topic)) {
			// const account = Cookie.get('token');
			// const email = Cookie.get('email');
			const collectionName = Cookie.get('collectionName');
			const id = {
				ackID: {
					// [`${account}/ackCheck`]: ackId,
					// [`${email}/ackCheck`]: ackId,
					[`${collectionName}/ackCheck`]: ackId,
				},
			};

			if (topic.includes('list')) payload.data.push(id);
			else payload = { ...payload, ...id }; // Object.assign({}, payload, ackID)

			registerAck(topic, payload, new Date());
			ackId = (ackId + 1) % 10000;
		}

		// return payload;
	};

	const registerAck = (topic, payload, payloadTime) => {
		acks.set(ackId, { topic, payload, payloadTime, payloadRepubTries: 0 });
	};

	const resolveAck = (id) => {
		id = parseInt(id);
		acks.delete(id);
	};

	const isAckPending = () => {
		// Acknowledgment ID 처리 여부 확인 로직
		// ackId가 아직 처리되지 않았는지 확인

		const currentTime = new Date();
		// let exhaustedUuidList = [];
		for (const [
			nackUuid,
			{ nackTopic, nackPayload, nackTime, nackRepubTries },
		] of acks.entries()) {
			try {
				console.log(
					'time',
					currentTime.getTime() / 1000 - nackTime.getTime() / 1000,
				);
				if (
					currentTime.getTime() / 1000 - nackTime.getTime() / 1000 >=
					MAX_WAITING_TIME
				) {
					if (nackRepubTries > MAX_NUM_RETRY) {
						// exhaustedUuidList.push(nackUuid);
						// close();
						mqttModule.reconnect();
					} else {
						const payloadRepubTries = nackRepubTries + 1;
						acks.set(nackUuid, {
							payload: nackPayload,
							payloadTime: new Date(),
							payloadRepubTries,
						});
						mqttModule.publish(nackTopic, nackPayload);
					}
				}
			} catch (error) {
				console.log(error);
				continue;
			}
		}
	};

	return {
		isAckExceptions,
		generateAckId,
		registerAck,
		resolveAck,
		isAckPending,
	};
};

export default AckHandler;
