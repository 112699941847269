import React from 'react';
import { observer } from 'mobx-react';

import * as S from './FooterLikuConnect.style';
// import { RootStoreContext } from '../../../../App';

import Image from '../../image/Image';

import LikuImage from '../../../../assets/image/likuwink/likuwink.webp';
import Liku2Image from '../../../../assets/image/likuwink/likuwink@2x.webp';
import Liku3Image from '../../../../assets/image/likuwink/likuwink@3x.webp';
import { RootStoreContext } from '../../../../App';

const FooterLikuConnect = observer(() => {
	const { likuStore, mqttStore } = React.useContext(RootStoreContext);

	const disconnectLiku = React.useCallback(() => {
		const likuJsonResponse = `liku/${likuStore.thisLiku.uuid}/robot/custom/response`;
		mqttStore.unsubscribeToTopic(likuJsonResponse);
		setTimeout(() => {
			likuStore.setThisLiku();
		}, 1000);
	});

	return (
		<S.Context>
			<S.Menu></S.Menu>
			<S.Menu></S.Menu>
			<S.LikuBox>
				{/*<S.Liku onClick={() => store.likuStore.setThisLiku()}>*/}
				<Image
					alt='liku'
					custom={S.Liku}
					srcArray={[LikuImage, Liku2Image, Liku3Image]}
					// onClick={() => store.likuStore.setThisLiku()}
				/>
				{/*</S.Liku>*/}
				<span>{likuStore.thisLiku.serial || 'IamNumber777'}</span>
				<S.DisConnectButton onClick={disconnectLiku}>x</S.DisConnectButton>
			</S.LikuBox>
			<S.Menu></S.Menu>
			<S.Menu></S.Menu>
		</S.Context>
	);
});

export default FooterLikuConnect;
