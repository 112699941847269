import axios from 'axios';
import * as Cookie from '../../utils/Cookie';

const BASE_URL = 'https://api.likuwithme.com/liku';
// const BASE_URL = 'http://3.38.179.203:80/liku';
// const BASE_URL = 'https://test.api.likuwithme.com/liku';

const axiosApi = (options) => {
	const api = axios.create({
		baseURL: `${BASE_URL}`,
		headers: {
			'Access-Control-Allow-Origin': 'https://test.api.likuwithme.com',
			'Access-Control-Allow-Credentials': 'true',
		},
		...options,
	});
	return api;
};

const axiosAuthApi = (options) => {
	const api = axios.create({
		baseURL: `${BASE_URL}`,
		headers: {
			Authorization: `Bearer ${Cookie.get('token')}`,
			'Access-Control-Allow-Origin': 'https://test.api.likuwithme.com',
			'Access-Control-Allow-Credentials': 'true',
		},
		...options,
	});
	interceptors(api);
	return api;
};

const axiosJsonAuthApi = (options) => {
	const api = axios.create({
		baseURL: `${BASE_URL}`,
		headers: {
			Authorization: `Bearer ${Cookie.get('token')}`,
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': 'https://test.api.likuwithme.com',
			'Access-Control-Allow-Credentials': 'true',
		},
		...options,
	});
	interceptors(api);
	return api;
};

const interceptors = (instance) => {
	instance.interceptors.request.use(
		(config) => {
			const token = Cookie.get('token');

			config.headers = {
				authorization: token ? `Bearer ${token}` : null,
			};
			return config;
		},
		(error) => Promise.reject(error.response),
	);
	return instance;
};

export const defaultApi = axiosApi();
export const authApi = axiosAuthApi();
export const authJsonApi = axiosJsonAuthApi();
