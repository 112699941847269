import React, { useState } from 'react';

import * as S from './Header.style';

import Torooc from '../../../assets/image/torooc/torooc-logo.webp';
import Torooc2 from '../../../assets/image/torooc/torooc-logo@2x.webp';
import Torooc3 from '../../../assets/image/torooc/torooc-logo@3x.webp';

import Apps from '../../../assets/image/apps/apps.webp';
import Apps2 from '../../../assets/image/apps/apps@2x.webp';
import Apps3 from '../../../assets/image/apps/apps@3x.webp';

import Alarm from '../../../assets/image/alarm/alarm.webp';
import Alarm2 from '../../../assets/image/alarm/alarm@2x.webp';
import Alarm3 from '../../../assets/image/alarm/alarm@3x.webp';

import Profile from '../../../assets/image/profile/profile.webp';
import Profile2 from '../../../assets/image/profile/profile@2x.webp';
import Profile3 from '../../../assets/image/profile/profile@3x.webp';

import Image from '../image/Image';
import { useNavigate } from 'react-router';
import { RootStoreContext } from '../../../App';
import { observer } from 'mobx-react';

const Header = observer(() => {
	const { likuStore, authStore, modeStore } =
		React.useContext(RootStoreContext);
	const navigate = useNavigate();
	const [profileOpen, setProfileOpen] = useState(false);

	return (
		<S.Container>
			<S.Content>
				<S.Left>
					<S.Logo
						alt='Torooc'
						srcSet={`${Torooc2} 2x,
							 			 ${Torooc3} 3x`}
						// sizes={}
						onClick={() => {
							navigate('/');
						}}
						src={Torooc}
						loading='lazy'
						decoding='async'
						fetchpriority='low'
					/>
				</S.Left>
				<S.Center>
					<S.Logo
						alt='Apps'
						srcSet={`${Apps2} 2x,
							 			 ${Apps3} 3x`}
						// sizes={}
						src={Apps}
						loading='lazy'
						decoding='async'
						fetchpriority='low'
					/>
				</S.Center>
				<S.Right>
					<S.ProfileWrap>
						<div
							style={{ display: 'none' }}
							onClick={() => {
								modeStore.setDarkMode(!modeStore.isDarkMode);
							}}>
							{'darkMode'}
						</div>
						<div
							style={{ display: 'none' }}
							onClick={() => {
								modeStore.setBigMode(!modeStore.isBigMode);
							}}>
							{'bigMode'}
						</div>
						<Image alt='Alarm' srcArray={[Alarm, Alarm2, Alarm3]} />
						<S.Profile>
							<div
								onClick={() => {
									console.log(profileOpen);
									setProfileOpen((state) => !state);
								}}>
								<Image
									alt='Profile'
									custom={S.ProfileImage}
									srcArray={[Profile, Profile2, Profile3]}
								/>
							</div>
							<S.ProfileModal open={profileOpen}>
								<S.ProfileModalName>
									<span className='bold'>{authStore.auth?.name}</span>
									{' 님'}
								</S.ProfileModalName>
								<S.LogoutButton
									onClick={() => {
										navigate('/login');
										authStore.logout();
										likuStore.setThisLiku();
									}}>
									로그아웃
								</S.LogoutButton>
							</S.ProfileModal>
						</S.Profile>
					</S.ProfileWrap>
				</S.Right>
			</S.Content>
		</S.Container>
	);
});

export default Header;
