import React, { useEffect, useRef, useState } from 'react';

import { Context } from './FooterLiku.style';
import * as S from './FooterLikuUnConnect.style';

import LikuImage from '../../../../assets/image/liku/liku.webp';
import Liku2Image from '../../../../assets/image/liku/liku@2x.webp';
import Liku3Image from '../../../../assets/image/liku/liku@3x.webp';

import LinkImage from '../../../../assets/image/link/link.webp';
import Link2Image from '../../../../assets/image/link/link@2x.webp';
import Link3Image from '../../../../assets/image/link/link@3x.webp';

import UnLinkImage from '../../../../assets/image/unlink/unlink.webp';
import UnLink2Image from '../../../../assets/image/unlink/unlink@2x.webp';
import UnLink3Image from '../../../../assets/image/unlink/unlink@3x.webp';

import FooterLikuList from './FooterLikuList';

import { useClick } from '../../../../hooks/useClick';

const Link = [LinkImage, Link2Image, Link3Image];
const UnLink = [UnLinkImage, UnLink2Image, UnLink3Image];

const FooterLikuUnConnect = () => {
	const { click: isLink, onClick: setIsLink } = useClick(false);
	const { click: isNew, onClick: setIsNew } = useClick(false);
	const connectBtnRef = useRef(null);
	const contentRef = useRef(null);
	const [diff, setDiff] = useState({ x: 0, y: 0, width: 0 });
	const [isLinkImage, setLinkImage] = useState(Link);

	useEffect(() => {
		setLinkImage(isLink ? UnLink : Link);
		if (!isLink && isNew) setIsNew(false);
	}, [isLink, isNew, setIsNew]);

	useEffect(() => {
		const { x: parentX, y: parentY } =
			contentRef.current.getBoundingClientRect();
		const { x, y, width } = connectBtnRef.current.getBoundingClientRect();
		const diffX = x - parentX;
		const diffY = y - parentY;

		setDiff({ x: diffX, y: diffY, width: width });
	}, [connectBtnRef.current, contentRef.current]);

	return (
		<>
			<Context ref={contentRef}>
				<S.LikuImg
					alt='LIKU'
					srcSet={`${Liku2Image} 2x,
                   ${Liku3Image} 3x`}
					// sizes={}
					src={LikuImage}
					loading='lazy'
					decoding='async'
					fetchpriority='low'
				/>
				<S.Info>
					{isLink && <S.State>연결 중</S.State>}
					<S.GradationBox click={isLink}>
						<S.Title>
							{isLink ? (
								isNew ? (
									<>새로운 리쿠 추가 중</>
								) : (
									<>어떤 리쿠를 연결할까요?</>
								)
							) : (
								<>
									만나서 반가워요!
									<br />
									저는 <span>소셜로봇</span> 리쿠에요.
								</>
							)}
						</S.Title>
					</S.GradationBox>
				</S.Info>
				<S.LinkButton click={isLink} onClick={setIsLink} ref={connectBtnRef}>
					<S.Circle>
						<S.LinkImg
							alt='Link'
							srcSet={`${isLinkImage[1]} 2x,
							${isLinkImage[2]} 3x`}
							// sizes={}
							src={isLinkImage[0]}
							loading='lazy'
							decoding='async'
							fetchpriority='low'
						/>
					</S.Circle>
					{isLink ? '연결 중지' : '로봇 연결'}
				</S.LinkButton>
			</Context>
			{!isLink && (
				<S.LinkInfo diff={diff}>
					<p className='sub'>
						해당 서비스는 <span className='highlight'>로봇 연결이 필요</span>
						합니다
					</p>
					<p className='main'>로봇을 선택해 주세요.</p>
				</S.LinkInfo>
			)}
			<FooterLikuList
				isLink={isLink}
				isNew={isNew}
				setIsLink={setIsLink.bind(this)}
				setIsNew={setIsNew.bind(this)}
			/>
		</>
	);
};

export default FooterLikuUnConnect;
