import styled, { css } from 'styled-components';

import { Context as C } from './FooterLiku.style';

export const Context = styled(C)`
	overflow: visible;
	padding: 1.36rem 5.7rem 1.24rem;
	box-sizing: border-box;
	${({ theme }) => theme.common.flex('row', 'center', 'space-around')};
`;

export const LikuBox = styled.div`
	position: relative;
	bottom: 3.24rem;
	padding: 1.25rem 0;
	box-sizing: border-box;
	border-radius: 10px;
	background-color: #ffffffd9; // 투명도 85%
	${({ theme }) => theme.common.wh('14rem')};
	${({ theme }) => theme.common.flex('column', 'center', 'space-between')};

	span {
		font-family: Helvetica-Regular;
		font-size: 1.2rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: #707070;
	}
`;

export const Liku = css`
	//background-color: #1d39c4;
	object-fit: cover;
	object-position: top;
	${({ theme }) => theme.common.wh('6.9rem', '9rem')};
`;

export const Menu = styled.div`
	border-radius: 10px;
	box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16),
		inset -1px -2px 8px 0 rgba(105, 72, 0, 0.2);
	background-color: #f0b30966; // 투명도 40%
	aspect-ratio: 1 / 1;
	${({ theme }) => theme.common.wh('auto', '100%')};
	${({ theme }) => theme.common.flex('row', 'center', 'center')};
`;

export const DisConnectButton = styled.button`
	position: absolute;

	top: 0;
	right: 0;
`;
