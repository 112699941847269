import styled, { keyframes } from 'styled-components';

const bounce = keyframes`
	0%,
	100% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(-20px);
	}
`;

export const LikuImg = styled.img`
	position: sticky;
	left: clamp(0rem, 8%, 1.3rem);
	//padding-top: -0.5rem;
	//background-color: #61dafb;
	//padding-top: 2%;
	z-index: 1;
	${({ theme }) => theme.common.wh('auto', '11.5rem')};
`;

export const Info = styled.div`
	position: sticky;
	right: clamp(5rem, 28%, 30rem);
	${({ theme }) => theme.common.wh('50%', '5.44rem')};
	${({ theme }) => theme.common.flex('column', 'flex-start', 'space-between')};
`;

export const State = styled.div`
	position: sticky;
	top: clamp(2rem, 30%, 40rem);
	left: clamp(13rem, 28%, 30rem);
	border-radius: 1rem;
	box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.16);
	background-color: #fcf1d3;
	font-family: HelveticaNeue-Medium;
	font-size: 0.8rem;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #211809;
	${({ theme }) => theme.common.wh('25%', '1.5rem')};
	${({ theme }) => theme.common.flex('column', 'center', 'center')};
`;

export const GradationBox = styled.div`
	border-radius: 1rem;
	box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
	background: linear-gradient(
		-90deg,
		rgba(255, 255, 255, 0.85) 55%,
		rgba(255, 255, 255, 0.1)
	);
	box-sizing: border-box;
	padding-left: clamp(4rem, 8%, 20rem);
	padding-right: clamp(1rem, 5%, 10rem);
	${({ theme, click }) => theme.common.wh('100%', click ? '2.5rem' : '100%')};
	${({ theme }) => theme.common.flex('column', 'center', 'center')};
`;

// ${({ theme }) => theme.common.wh('50%', '5.44rem')};
// ${({ theme }) => theme.common.wh('50%', '2.5rem')};
export const Title = styled.span`
	font-family: HelveticaNeue-Light;
	font-size: 1.2rem;
	font-weight: 300;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.42;
	letter-spacing: normal;
	text-align: center;
	color: #201708;

	span {
		font-weight: bold;
	}
`;

export const LinkButton = styled.button`
	position: sticky;
	right: clamp(0rem, 15%, 10rem);
	padding: 0;
	border: 0;
	background-color: transparent;
	//position: absolute;
	//right: 7%;
	//aspect-ratio: 1 / 1.3789473684210527;
	font-family: HelveticaNeue-Regular;
	font-size: 0.8rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #211809;

	${({ theme }) => theme.common.wh('3.8rem', '5.24rem')};
	${({ theme }) => theme.common.flex('column', 'center', 'space-between')};
`;

export const Circle = styled.div`
	width: 100%;
	aspect-ratio: 1 / 1;
	background-color: #fcf1d5;
	//border-radius: 1.9rem;
	border-radius: 100%;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
	${({ theme }) => theme.common.flex('column', 'center', 'center')};
`;

export const LinkImg = styled.img`
	width: 50%;
	aspect-ratio: 1 / 1;
	//object-fit: contain;
`;

export const LinkInfo = styled.div`
	box-sizing: border-box;
	width: 60%;
	border: 1px solid ${({ theme }) => theme.color.orange};
	animation: ${bounce} 1s infinite ease-in-out;
	position: absolute;
	left: ${({ diff }) => `calc(${diff.x + 0.5 * diff.width}px - 30%)`};
	background-color: #fff;
	top: ${({ diff }) => `calc(${-diff.y}px - 60%)`};
	padding: 1.3rem;
	z-index: 1;
	border-radius: 1.6rem;
	box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.08);
	p {
		text-align: center;
		font-size: 1.6rem;
		color: ${({ theme }) => theme.color.gray_42};
		line-height: 2rem;
		&.sub {
			font-size: 1.2rem;
		}
		&.main {
			font-weight: bold;
		}
		& .highlight {
			font-weight: bold;
			color: ${({ theme }) => theme.color.orange};
			text-decoration: underline;
		}
	}
	&::before {
		content: '↓';
		position: absolute;
		width: 100%;
		font-size: 4.4rem;
		height: 2.4rem;
		bottom: -1.8rem;
		text-align: center;
		left: 0;
		right: 0;
		color: ${({ theme }) => theme.color.orange};
	}
`;
