import { useState } from 'react';

export const useClick = (initialValue) => {
	const [value, setValue] = useState(initialValue);

	const handleClick = () => {
		setValue(!value);
	};

	return {
		click: value,
		onClick: handleClick,
	};
};
