import styled from 'styled-components';

export const LaunchpadApp = styled.div`
	box-sizing: border-box;
	aspect-ratio: auto 1 / 1.217142857142857;
	min-width: 100%;
	width: 100%;
	${({ theme }) => theme.common.flex('column', 'center', 'space-between')};
	${({ theme }) => theme.common.wh('max-content', 'auto')};
`;

export const LaunchpadAppIcon = styled.div`
	aspect-ratio: auto 1 / 1;
	border-radius: 10px;
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16);
	background-color: rgba(255, 255, 255, 0.95);
	${({ theme }) => theme.common.wh('calc(100% - 6px)', 'auto')};
`;

export const LaunchpadAppTitle = styled.div`
	font-family: HelveticaNeue-Regular;
	font-size: 0.8rem;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #211809;
	margin-bottom: 5%;
	${({ theme }) => theme.common.wh('100%', 'auto')};
	${({ theme }) => theme.common.flex('row', 'center', 'center')};
`;

export const App = styled.div`
	//height: calc(100% - 2.2rem);
	height: 100%;
	aspect-ratio: auto 1 / 1;
	//margin: 0 clamp(0.9rem, 12%, 1rem);
	opacity: 0.95;
	border-radius: 10px;
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16);
	background-color: #ffffff;
	&.Small {
		min-height: 3.85rem;
	}
	&.Big {
		min-height: 5rem;
	}
`;
