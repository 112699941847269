import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { RootStoreContext } from '../App';
import { observer } from 'mobx-react';

const PublicRouter = observer(({ restricted }) => {
	const store = useContext(RootStoreContext);

	return store.authStore.isLogin() && restricted ? (
		<Navigate to='/' />
	) : (
		<Outlet />
	);
});

export default PublicRouter;
