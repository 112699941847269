import { makeAutoObservable } from 'mobx';

import Mqtt from '../../services/mqtt';
import TopicHandlerStrategy from '../../services/mqtt/TopicHandlerStrategy';
import AckHandler from '../../services/mqtt/AckHandler';

class MqttStore {
	rootStore;
	isConnected = false;
	subscribedTopics = new Set();
	receivedMessages = [];

	topicHandlerStrategy = TopicHandlerStrategy();
	mqttModule = null;
	ackHandler = null;

	constructor(root) {
		makeAutoObservable(this);
		this.rootStore = root;

		// this.connectedHandler = reaction(
		// 	() => this.mqttModule?.connected,
		// 	(connected) => {
		// 		console.log('mqttModule', connected);
		// 		if (connected) this.handleConnect();
		// 		else this.handleDisconnect();
		// 	},
		// );
	}

	setIsConnected(value) {
		this.isConnected = value;
	}

	connect(callback) {
		if (this.mqttModule) this.mqttModule.reconnect();
		else {
			this.mqttModule = Mqtt(
				this.topicHandlerStrategy,
				this.setIsConnected.bind(this),
				callback,
			);
			this.ackHandler = AckHandler(this.mqttModule);
		}
	}

	disconnect() {
		this.mqttModule.disconnect();
	}

	handleMessage(topic, message) {
		this.topicHandlerStrategy.handleMessage(topic, message);
		this.receivedMessages.push({ topic, message });
	}

	subscribeToTopic(topic, handler) {
		if (!this.subscribedTopics.has(topic)) {
			if (topic.includes('ack')) {
				handler = this.ackHandler.resolveAck.bind(this.ackHandler);
			}

			if (handler) this.topicHandlerStrategy.addHandler(topic, handler);
			this.mqttModule.subscribe(topic);
			this.subscribedTopics.add(topic);
		}
	}

	unsubscribeToTopic(topic) {
		if (this.subscribedTopics.has(topic)) {
			this.topicHandlerStrategy.removeHandler(topic);
			this.mqttModule.unsubscribe(topic);
			this.subscribedTopics.delete(topic);
		}
	}

	publishMessage(topic, message, options) {
		this.mqttModule.publish(topic, message, options);
		this.ackHandler.generateAckId(topic, message);
	}

	// dispose() {
	// 	this.connectedHandler();
	// }
}

export default MqttStore;
