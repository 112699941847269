import React from 'react';
import { observer } from 'mobx-react';

import FooterMenu from './FooterMenu';
import { RootStoreContext } from '../../../App';

import * as S from './FooterDock.style';

const Menu = (className) => {
	return Array(2)
		.fill('')
		.map((value, index) => <S.App key={index} className={className} />);
};

const FooterDock = observer(() => {
	const store = React.useContext(RootStoreContext);

	return (
		<FooterMenu
			className={'Dock'}
			isLiku={store.likuStore.thisLiku}
			title={'바로가기'}
			info={'전체 서비스'}
			menu={Menu(store.likuStore.thisLiku ? 'Small' : 'Big')}>
			{new Array(8).fill('a').map((value, index) => (
				<S.LaunchpadApp key={index}>
					<S.LaunchpadAppIcon />
					<S.LaunchpadAppTitle>리쿠</S.LaunchpadAppTitle>
				</S.LaunchpadApp>
			))}
		</FooterMenu>
	);
});

export default FooterDock;
