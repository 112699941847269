import React from 'react';
import { observer } from 'mobx-react';
import { FaPlus } from 'react-icons/fa';

import * as S from './FooterLikuList.style';
import { RootStoreContext } from '../../../../App';

import Image from '../../image/Image';

import LikuImage from '../../../../assets/image/likuicon/likuicon.webp';
import Liku2Image from '../../../../assets/image/likuicon/likuicon@2x.webp';
import Liku3Image from '../../../../assets/image/likuicon/likuicon@3x.webp';

const FooterLikuList = observer(({ isLink, isNew, setIsLink, setIsNew }) => {
	const store = React.useContext(RootStoreContext);
	const [liku, setLiku] = React.useState('');

	// const addLiku = React.useCallback(async () => {
	// 	const result = await store.likuStore.addLiku(liku);
	// 	if (result) setIsNew(!isNew);
	// 	else setLiku('');
	// }, [liku]);
	const addLiku = React.useCallback(async () => {
		const result = await store.likuStore.addLiku(liku);
		if (result)
			setIsNew((prevIsNew) => !prevIsNew); // 이전 상태를 받아와서 업데이트
		else setLiku('');
	}, [liku, setIsNew, store.likuStore]);

	const connectLiku = React.useCallback(
		(liku) => {
			store.likuStore.setThisLiku(liku);
			setIsLink();
		},
		[isLink],
	);

	return (
		<S.Container click={isLink}>
			{/*<S.Context click={isLink} isNew={isNew}>*/}
			<S.Context click={isLink} isNew={isNew}>
				{isNew ? (
					<S.NewLiku>
						<S.Example>입력 예시 : 2011K504</S.Example>
						<S.SerialInput
							placeholder='시리얼 넘버를 입력해주세요.'
							value={liku}
							onChange={(e) => setLiku(e.target.value)}
						/>
					</S.NewLiku>
				) : (
					<S.LikuWrap>
						{store.likuStore.likus.map((liku) => (
							<S.LikuBox key={liku?.serial} onClick={() => connectLiku(liku)}>
								<Image
									alt='liku'
									srcArray={[LikuImage, Liku2Image, Liku3Image]}
									custom={S.Liku}
								/>
								<span>{liku?.serial}</span>
							</S.LikuBox>
						))}
					</S.LikuWrap>
				)}
			</S.Context>
			<S.Bottom>
				<S.AddButton onClick={() => (isNew ? addLiku() : setIsNew())}>
					<S.PlusCircle>
						<FaPlus size='1rem' color='#fff' />
					</S.PlusCircle>
					<S.Name>{isNew ? '등록' : '새로운 리쿠 추가'}</S.Name>
				</S.AddButton>
			</S.Bottom>
		</S.Container>
	);
});

export default FooterLikuList;
