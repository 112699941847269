import AuthStore from './domain/AuthStore';
import LikuStore from './domain/LikuStore';
import MqttStore from './domain/MqttStore';
import GreetStore from './domain/GreetStore';
import ModeStore from './domain/ModeStore';
// import UiStore from './ui/UiStroe';

class RootStore {
	constructor() {
		this.authStore = new AuthStore(this);
		this.likuStore = new LikuStore(this);
		this.mqttStore = new MqttStore(this);
		this.greetStore = new GreetStore(this);
		this.modeStore = new ModeStore(this);
		// this.uiStore = new UiStore(this);
	}
}

export default RootStore;
