// import styled, { keyframes } from 'styled-components';
import styled, { css } from 'styled-components';

// export const Container = styled.div`
// 	position: absolute;
// 	bottom: 7.42rem;
// 	box-sizing: border-box;
// 	border-radius: 2rem;
// 	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16);
// 	background-color: #ffffff;
// 	transition: all 0.7s ease-in-out;
//
// 	padding: ${({ click }) =>
// 		!click ? '0rem' : '0.35rem 0.35rem 4.1rem 0.35rem'};
// 	max-height: ${({ click }) => (!click ? '0rem' : '27rem')};
// 	${({ theme }) => theme.common.wh('75%', 'auto')};
// 	${({ theme }) => theme.common.flex('column', 'center', 'space-between')};
//
// 	&.isNew {
// 		max-height: ${({ click }) => (!click ? '0rem' : '15rem')};
// 	}
// `;
export const Container = styled.div`
	position: absolute;
	bottom: 7.42rem;
	box-sizing: border-box;
	border-radius: 2rem;
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16);
	background-color: #ffffff;
	transition: all 1.5s;

	padding: ${({ click }) =>
		!click ? '0rem' : '0.35rem 0.35rem 4.1rem 0.35rem'};
	${({ theme }) => theme.common.wh('75%', 'auto')};
	${({ theme }) => theme.common.flex('column', 'center', 'space-between')};
`;

export const Context = styled.div`
	transition: all 1.5s;
	max-height: ${({ click, isNew }) =>
		!click ? '0rem' : !isNew ? '27rem' : '15rem'};
	${({ theme }) => theme.common.wh('100%', 'auto')};
	${({ theme }) => theme.common.flex('column', 'flex-start', 'flex-start')};
`;

export const Bottom = styled.div`
	${({ theme }) => theme.common.wh('100%', '2.5rem')};
	${({ theme }) => theme.common.flex('row', 'center', 'center')};
`;
// const sizeAnimation = keyframes`
//   from {
//     opacity: 0;
//     transform: translateY(-5%);
//   }
//   to {
//     opacity: 1;
//     transform: translateY(0);
//   }
// `;

export const NewLiku = styled.div`
	padding: 1.55rem 2.65rem 1.3rem 2.25rem;
	box-sizing: border-box;
	${({ theme }) => theme.common.wh('100%', '8.05rem')};
	${({ theme }) => theme.common.flex('column', 'flex-start', 'space-between')};
`;
//animation: ${sizeAnimation};

export const Example = styled.span`
	font-family: HelveticaNeue-Light;
	font-size: 0.8rem;
	font-weight: 300;
	font-stretch: normal;
	font-style: normal;
	line-height: 2.13;
	letter-spacing: normal;
	text-align: left;
	color: #707070;
	${({ theme }) => theme.common.wh('100%', '1rem')};
	${({ theme }) => theme.common.flex('column', 'flex-start', 'center')};
`;

export const SerialInput = styled.input`
	border-radius: 1rem;
	border: solid 1px #e4e4e4;
	background-color: #fff;
	padding: 1.2rem 2.5rem 1.1rem 2.5rem;
	box-sizing: border-box;
	font-family: Helvetica-Light;
	font-size: 1.2rem;
	font-weight: 300;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.42;
	letter-spacing: normal;
	text-align: left;
	color: #ababab;
	outline: none;
	${({ theme }) => theme.common.wh('100%', '3.8rem')};
	${({ theme }) => theme.common.flex('column', 'flex-start', 'space-between')};
`;

export const LikuWrap = styled.div`
	padding: 0.75rem;
	//padding: 1.1rem;
	box-sizing: border-box;
	overflow-y: auto;
	display: grid;
	row-gap: 1.5rem;
	${({ theme }) => theme.common.wh('100%', '19.35rem')};
`;

export const LikuBox = styled.button`
	border: none;
	border-radius: 1rem;
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16),
		inset -1px -1px 2px 0 rgba(0, 0, 0, 0.16);
	background-color: #f5f5f5;
	//margin-bottom: 1.5rem;
	min-height: 4.5rem;
	padding: 0 1.99rem;
	box-sizing: border-box;
	${({ theme }) => theme.common.wh('100%', '4.5rem')};
	${({ theme }) => theme.common.flex('row', 'center', 'flex-start')};

	span {
		font-family: Helvetica-Regular;
		font-size: 1.2rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: #444b54;
		margin: 0 0 1% 0.88rem;
	}
`;

export const Liku = css`
	object-fit: cover;
	${({ theme }) => theme.common.wh('1.55rem', '1.25rem')};
`;

export const AddButton = styled.button`
	box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
	background-color: #fff;
	border-radius: 1.5rem;
	border: none;
	//padding: 0.45rem 0.48rem 0.55rem;
	padding: 0.45rem 0.48rem;
	box-sizing: border-box;
	${({ theme }) => theme.common.wh('40%', '100%')};
	${({ theme }) => theme.common.flex('row', 'center', 'center')};
`;

export const PlusCircle = styled.div`
	aspect-ratio: 1 / 1;
	border-radius: 100%;
	//padding: 0 0 4.5% 0;
	box-sizing: border-box;
	box-shadow: inset -1px -1px 2px 0 rgba(0, 0, 0, 0.16);
	background-color: #f4a90f;
	//font-family: HelveticaNeue-Bold;
	//font-size: 2rem;
	//font-weight: bold;
	//font-stretch: normal;
	//font-style: normal;
	//line-height: normal;
	//letter-spacing: normal;
	//text-align: center;
	//color: #fff;
	${({ theme }) => theme.common.wh('auto', '100%')};
	${({ theme }) => theme.common.flex('column', 'center', 'center')};
`;

export const Name = styled.div`
	font-family: HelveticaNeue-Bold;
	font-size: 0.8rem;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: center;
	color: #444b54;
	${({ theme }) => theme.common.wh('85%', '100%')};
	${({ theme }) => theme.common.flex('column', 'center', 'center')};
`;
