import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

const global = createGlobalStyle`
  ${reset}; 

  html {
    //width: 100%;
    //height: 100%;
    font-size: 62.5%;
    /* font-size: 100%; */
  }
  
  body {
    font-size: 1.6rem;
    font-family: Helvetica-Regular;
    background-color: ${({ theme }) => theme.color.background};
  }

  button {
    cursor: pointer;
  }

  .skeleton {
  background: linear-gradient(90deg, #f0f0f0 25%, #f8f8f8 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  color: transparent;
  }

  @keyframes shimmer {
    from {
      background-position: -200% 0;
    } 
    to {
      background-position: 200% 0;
    }
  }
`;

export default global;
