import React, { useContext, useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { RootStoreContext } from '../App';
import { observer } from 'mobx-react';
import Header from '../components/common/header/Header';
import Footer from '../components/common/footer/Footer';

const PrivateRouter = observer(() => {
	const store = useContext(RootStoreContext);

	useEffect(() => {
		const isLogin = store.authStore.isLogin();
		if (isLogin) store.authStore.loadAuth();
	}, [store.authStore]);

	return store.authStore.isLogin() ? (
		<>
			<Header />
			<Outlet />
			<Footer />
		</>
	) : (
		<Navigate to='/login' />
	);
});

export default PrivateRouter;
