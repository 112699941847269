import React from 'react';
import * as S from './FooterMenu.style';

import { useClick } from '../../../hooks/useClick';

const FooterMenu = (props) => {
	const { className, isLiku, title, info, menu, children } = props;

	// const click = useClick(false); // value, onClick
	const { click, onClick } = useClick(false);

	return (
		// <S.Container {...click}>
		<S.Container click={click} onClick={onClick}>
			<S.Context click={click}>
				<S.ContextTop>
					<S.ContextTitle>{title}</S.ContextTitle>
					<S.ContextInfo>{info}</S.ContextInfo>
				</S.ContextTop>
				<S.ContextWrap>{children}</S.ContextWrap>
			</S.Context>
			<S.Menus
				className={`${className} ${isLiku ? 'Small' : 'Big'}`}
				click={click}>
				{menu}
			</S.Menus>
		</S.Container>
	);
};

export default FooterMenu;
