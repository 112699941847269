import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Layout from '../components/common/layout/Layout';
import PublicRouter from './PublicRouter';
import PrivateRouter from './PrivateRouter';
import { MainPage, LoginPage, ChatBotPage } from '../pages';

const Router = () => {
	return (
		<BrowserRouter>
			<Suspense fallback={<div>Loading...</div>}>
				<Routes>
					<Route element={<PrivateRouter />}>
						<Route element={<Layout />}>
							<Route path='/main' element={<MainPage />} />
							<Route path='/custom/chatbot' element={<ChatBotPage />} />
						</Route>
					</Route>
					<Route element={<PublicRouter />}>
						<Route path='/' element={<LoginPage />} />
						<Route path='/login' element={<LoginPage />} />
					</Route>
				</Routes>
			</Suspense>
		</BrowserRouter>
	);
};

export default Router;
