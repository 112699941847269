import { Cookies } from 'react-cookie';

const cookies = new Cookies();

export const set = (name, value, option) => {
	if (value !== undefined) return cookies.set(name, value, option);
};

export const get = (name) => {
	return cookies.get(name);
};

export const remove = (name, option) => {
	return cookies.remove(name, option);
};

export const getAll = () => {
	return cookies.getAll();
};

export const removeAll = () => {
	let cookieAll = getAll();
	for (let cookie of Object.keys(cookieAll)) {
		remove(cookie, { path: '/', maxAge: 43200 });
	}
};

export const refresh = () => {
	let cookieAll = getAll();
	for (let cookie of Object.keys(cookieAll)) {
		set(cookie, cookieAll.cookie, { path: '/', maxAge: 43200 });
	}
};
