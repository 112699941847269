import styled from 'styled-components';

export const Container = styled.div`
	position: relative;
	//min-width: 65rem;
	min-width: ${({ thisLiku }) => (thisLiku ? '65rem' : '42.8rem')};
	${({ theme }) => theme.common.wh('100%', 'auto')};
	${({ theme }) => theme.common.flex('row', 'center', 'center')};
`;

export const Context = styled.div`
	position: relative;
	z-index: 1;
	background-color: #fed242;
	border-radius: 120px 120px 65px 65px;
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1),
		inset 0 -5px 10px 0 rgba(199, 139, 0, 0.35);
	overflow: hidden;

	${({ theme }) => theme.common.wh('100%', '10rem')};
	${({ theme }) => theme.common.flex('row', 'center', 'center')};
`;
