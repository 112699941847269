import React from 'react';
import { DefaultImage } from './Image.style';

const Image = ({ alt, srcArray, custom }) => {
	return (
		<DefaultImage
			custom={custom}
			alt={alt}
			srcSet={`${srcArray?.[1] || srcArray?.[0]} 2x,
			         ${srcArray?.[2] || srcArray?.[0]} 3x`}
			// sizes={}
			src={srcArray?.[0]}
			loading='lazy'
			decoding='async'
			fetchpriority='low'
		/>
	);
};

export default Image;
