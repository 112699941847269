const TopicHandlerStrategy = () => {
	const handlers = new Map();

	const addHandler = (topic, handler) => {
		handlers.set(topic, handler);
	};

	const removeHandler = (topicPattern) => {
		handlers.delete(topicPattern);
	};

	const handleMessage = (topic, payload) => {
		for (const [topicPattern, handler] of handlers) {
			// console.log('topicPattern', topicPattern);
			if (topic.match(topicPattern)) {
				handler(payload);
				console.log('topicPattern match!!!', topicPattern);
				return;
			}
		}

		console.log('Unknown topic:', topic);
	};

	return {
		addHandler,
		removeHandler,
		handleMessage,
	};
};

export default TopicHandlerStrategy;
