import { authApi } from './index';

export const getLikuAll = async () => {
	try {
		const { data } = await authApi.get('/portal/collection/likus');

		console.log('getLikuAll', data);
		return data;
	} catch (error) {
		console.error(error);
	}
};

export const addLiku = async (request) => {
	try {
		const { data } = await authApi.post('/portal/collection/liku', request);

		console.log('addLiku', data);
		return data;
	} catch (error) {
		return error.response.data;
	}
};
