import React from 'react';
import { observer } from 'mobx-react';

import FooterMenu from './FooterMenu';
import { RootStoreContext } from '../../../App';

import { App } from './FooterDock.style';

const Menu = (className) => {
	return Array(2)
		.fill('')
		.map((value, index) => <App key={index} className={className} />);
};

const FooterAlarm = observer(() => {
	const store = React.useContext(RootStoreContext);
	// const [liku, setLiku] = useState(true);

	return (
		<FooterMenu
			className={'Alarm'}
			title={'새소식'}
			info={''}
			// onClick={() => setLiku(true)}
			menu={Menu(store.likuStore.thisLiku ? 'Small' : 'Big')}>
			{/*<S.AlarmWarp click={liku}></S.AlarmWarp>*/}
		</FooterMenu>
	);
});

export default FooterAlarm;
