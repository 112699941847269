import { makeAutoObservable, runInAction, when } from 'mobx';

import * as AuthApi from '../../services/apis/AuthApi';
import * as Cookie from '../../utils/Cookie';

import { Auth } from './Auth';

class AuthStore {
	rootStore;

	auth = null;
	auto;

	constructor(root) {
		makeAutoObservable(this);
		this.rootStore = root;
		when(() => !!this.auth);
		// this.login();
	}

	get isManager() {
		return this.auth?.type !== 'PERSONAL';
	}

	isLogin() {
		const isToken = Cookie.get('token');
		const isCollectionName = Cookie.get('collectionName');
		if (!isToken && !isCollectionName) Cookie.removeAll();

		return isToken && isCollectionName;
	}

	isEmail(email) {
		const emailRegex = new RegExp(
			/^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/,
		);
		const result = emailRegex.test(email);

		return result;
	}

	isPassword(password) {
		// const EngRegex = new RegExp(/[a-zA-Z]/);
		// const NumRegex = new RegExp(/[0-9]/);
		// const SpecialRegex = new RegExp(/[$@$!%*?&]/);
		// const isEngPassword = EngRegex.test(password);
		// const isNumPassword = NumRegex.test(password);
		// const isSpecialPassword = SpecialRegex.test(password);
		//
		// const passwordLength = password.length;
		// const result =
		// 	passwordLength >= 8 &&
		// 	passwordLength <= 20 &&
		// 	((isNumPassword && isEngPassword) ||
		// 		(isEngPassword && isSpecialPassword) ||
		// 		(isSpecialPassword && isNumPassword));
		//
		// return result;

		const passwordLength = password.length;
		return passwordLength > 0 ? true : false;
	}

	// async existsEmail(email) {
	// 	Cookie.set(
	// 		'token',
	// 		'eyJhbGciOiJIUzUxMiJ9.eyJleHAiOjI1NDg4OTY5MzIsImlhdCI6MTY4NDg5NjkzMiwianRpIjoidG9yb29jQHRvcm9vYy5jb20ifQ.VtjgjnLHaWqQxea5AzaNV8O17_c67aL9JSJdN29ROvDACTfR4eUx9ezkZWphlNO_8hdV-qV_0KCiqiI2GbAcZw',
	// 		{ path: '/', maxAge: 86400 },
	// 	);
	// 	const result = await AuthApi.findMemberByEmail(email);
	// 	Cookie.remove('token');
	//
	// 	return result;
	// }

	isAuthInput(authData) {
		const { email, password } = authData;

		const isEmail = this.isEmail(email);
		const isPassword = this.isPassword(password);

		if (isEmail && isPassword) return true;

		return false;
	}

	// async signup(value) {
	// 	Cookie.set(
	// 		'token',
	// 		'eyJhbGciOiJIUzUxMiJ9.eyJleHAiOjI1NDg4OTY5MzIsImlhdCI6MTY4NDg5NjkzMiwianRpIjoidG9yb29jQHRvcm9vYy5jb20ifQ.VtjgjnLHaWqQxea5AzaNV8O17_c67aL9JSJdN29ROvDACTfR4eUx9ezkZWphlNO_8hdV-qV_0KCiqiI2GbAcZw',
	// 		{ path: '/', maxAge: 86400 },
	// 	);
	// 	let result = await AuthApi.setMember(value);
	// 	if (result === 'success') {
	// 		const auth = await AuthApi.authenticate({
	// 			email: value.email,
	// 			password: value.password,
	// 		});
	// 		await Cookie.set('token', auth?.token, {
	// 			path: '/',
	// 			maxAge: 86400,
	// 		});
	//
	// 		const collectionName = value.email.split('@')[0];
	// 		let collectionNum = '';
	// 		for (let i = 0; i < 5; i++) {
	// 			result =
	// 				(await AuthApi.setCollection({
	// 					name: `${collectionName}${collectionNum}`,
	// 				})) === 200;
	// 			collectionNum = collectionNum === '' ? 1 : collectionNum + 1;
	// 		}
	// 		if (!result) return false;
	//
	// 		result =
	// 			(await AuthApi.setProfile({
	// 				email: value.email,
	// 				name: '',
	// 				role: 'USER',
	// 			})) && result;
	//
	// 		result =
	// 			(await AuthApi.setCollectionProfile({
	// 				profile: { email: value.email, name: '', role: 'USER' },
	// 				collection: {
	// 					name: `${collectionName}${collectionNum - 1}`,
	// 				},
	// 			})) && result;
	// 	}
	//
	// 	Cookie.remove('token');
	//
	// 	if (result) return true;
	// 	else return false;
	// }

	logout() {
		Cookie.removeAll();
		this.auth = null;
	}

	async loadAuth(auth = {}) {
		if (Object.keys(auth).length > 0) {
			Cookie.set('id', auth.id, { path: '/', maxAge: 86400 });
			Cookie.set('usage', auth.usage, { path: '/', maxAge: 86400 });
			Cookie.set('name', auth.name, { path: '/', maxAge: 86400 });
			Cookie.set('role', auth.role, { path: '/', maxAge: 86400 });
			Cookie.set('token', auth.token, { path: '/', maxAge: 86400 });
			Cookie.set('parentId', auth.parentId, { path: '/', maxAge: 86400 });
			Cookie.set('type', auth.type, { path: '/', maxAge: 86400 });
		} else {
			auth.id = Cookie.get('id');
			auth.usage = Cookie.get('usage');
			auth.name = Cookie.get('name');
			auth.role = Cookie.get('role');
			auth.token = Cookie.get('token');
			auth.collectionName = Cookie.get('collectionName');
			auth.parentId = Cookie.get('parentId');
			auth.type = Cookie.get('type');
			Cookie.refresh();
		}

		const result = await AuthApi.getCollectionMember();
		console.log('myCollection', result?.collection);
		auth.collectionName = result?.collection?.name;
		Cookie.set('collectionName', auth.token, {
			path: '/',
			maxAge: 86400,
		});

		runInAction(() => {
			this.auth = new Auth(this, auth);
			console.log('thisAuth', this.auth);
		});
		await this.rootStore.likuStore.loadLiku();
		// await this.rootStore.userStore.loadUser();
		// await this.rootStore.userFaceStore.loadUserFace();
		// await this.rootStore.alarmStore.loadAlarm();
		// await this.rootStore.customStore.loadCustom();
	}

	// get getAccessToken() {
	// 	return this.token;
	// }
}

export default AuthStore;
