import mqtt from 'mqtt/dist/mqtt';

const URL =
	'ws://ec2-54-180-207-182.ap-northeast-2.compute.amazonaws.com:15675';
const QOS = { qos: 1 };
const OPTIONS = {
	keepalive: 30,
	protocolId: 'MQTT',
	protocolVersion: 4,
	clean: true,
	path: '/ws',
	reconnectPeriod: 1000,
	connectTimeout: 30 * 1000,
	will: {
		topic: 'WillMsg',
		payload: 'Connection Closed abnormally..!',
		retain: false,
		...QOS,
	},
	rejectUnauthorized: false,
	clientId: `mqttjs_${Math.random().toString(16).substr(2, 8)}`,
	username: 'liku',
	password: 'likuliku20',
};

const Mqtt = (topicHandlerStrategy, setIsConnected, callback) => {
	const client = mqtt.connect(URL, OPTIONS);

	client.on('connect', () => {
		console.log('Client connected:');
		setIsConnected(true);
		callback && callback();
	});
	client.on('close', () => {
		console.log('Client closed:');
		setIsConnected(false);
	});
	client.on('error', (error) => {
		console.error('Connection error: ', error);
		setIsConnected(false);
	});

	client.on('message', (topic, message) => {
		// const originMessage = message;
		const enc = new TextDecoder('utf-8');
		const arr = new Uint8Array(message);
		try {
			message = JSON.parse(enc.decode(arr));
		} catch (error) {
			message = enc.decode(arr);
		}

		// if (!topic.includes('heartbeat')) {
		// console.log('topic', topic);
		// console.log('message', message);
		console.log(`${topic}: ${JSON.stringify(message)}`);
		// }

		// 메시지 핸들링을 위해 TopicHandlerStrategy 호출
		topicHandlerStrategy.handleMessage(topic, message);
	});

	const reconnect = () => {
		disconnect();
		// this.connect();
		client?.reconnect(true, {}, () => {
			console.log('Client reconnect:');
		});
	};

	const disconnect = () => {
		if (client) {
			client.end(true, {}, () => {
				console.log('Client end:');
			});
		}
	};

	const publish = (topic, payload, options) => {
		if (client) {
			if (typeof payload === 'object') payload = JSON.stringify(payload);
			client.publish(topic, payload, { ...QOS, ...options }, (error) => {
				if (error) {
					console.log('Publish error: ', error);
				}
			});
			console.log('publish topic', topic);
			console.log('publish payload', payload);
		}
	};

	const subscribe = (topic) => {
		if (client) {
			client.subscribe(topic, QOS, (error) => {
				if (error) {
					console.log('Subscribe to topics error', error);
				}
			});
			console.log('subscribe', topic);
		}
	};

	const unsubscribe = (topic) => {
		if (client) {
			client.unsubscribe(topic, {}, (error) => {
				if (error) {
					console.log('unSubscribe to topics error', error);
				}
			});
			console.log('unsubscribe', topic);
		}
	};

	return {
		reconnect,
		disconnect,
		publish,
		subscribe,
		unsubscribe,
	};
};

export default Mqtt;
