import styled from 'styled-components';

export const Container = styled.main`
	min-height: calc(100vh - 14rem);
	position: sticky;
	box-sizing: border-box;
	//padding: 7.5rem 0 0 0; // header_height: 6.5rem + padding_top: 1rem
	//margin: 0 0 36.4rem 0;
	// footer_height: 10rem
	padding: 7.5rem 0 0 0; // header_height: 7.5rem
	${({ theme }) => theme.common.container};
`;
