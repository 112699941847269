import { makeAutoObservable, runInAction } from 'mobx';

class ModeStore {
	rootStore;
	isDarkMode = false;
	isBigMode = false;

	constructor(root) {
		makeAutoObservable(this);
		this.isDarkMode = localStorage.getItem('isDarkMode') === 'true';
		this.isBigMode = localStorage.getItem('isBigMode');
		this.rootStore = root;
	}

	setDarkMode(_value) {
		runInAction(() => {
			localStorage.setItem('isDarkMode', _value);
			this.isDarkMode = _value;
		});
	}

	setBigMode(_value) {
		runInAction(() => {
			localStorage.setItem('isBigMode', _value);
			this.isBigMode = _value;
		});
	}
}

export default ModeStore;
