import { makeAutoObservable } from 'mobx';

export class Auth {
	id;
	usage;
	name;
	role;
	token;
	collectionName;
	parentId;
	type;

	store;

	constructor(store, auth) {
		makeAutoObservable(this);
		this.store = store;

		this.id = auth?.id;
		this.usage = auth?.usage;
		this.name = auth?.name;
		this.role = auth?.role;
		this.token = auth?.token;
		this.collectionName = auth?.collectionName;
		this.parentId = auth?.parentId;
		this.type = auth?.type;
	}
}
