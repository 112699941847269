import { authApi, defaultApi } from './index';

export const authenticate = async (request) => {
	try {
		const { data } = await defaultApi.post('/authenticate', {
			...request,
		});

		console.log('authenticate result', data);
		return data;
	} catch (error) {
		console.error(error);
	}
};

export const getCollectionMember = async () => {
	try {
		const { data } = await authApi.get('/portal/collection/member');

		console.log('getCollectionMember result', data);
		return data;
	} catch (error) {
		console.error(error);
	}
};

export const findMemberByEmail = async (email) => {
	try {
		const { data } = await authApi.get(`/torooc/member/exists?email=${email}`);

		console.log('findMemberByEmail result', data);
		return data;
	} catch (error) {
		console.error(error);
	}
};

export const setMember = async (request) => {
	try {
		const { data } = await authApi.post('/torooc/join/member/allRoles', {
			...request,
		});

		console.log('setMember result', data);
		return data;
	} catch (error) {
		console.error(error);
	}
};

export const setCollection = async (request) => {
	try {
		const { data } = await authApi.post('/portal/collection', {
			...request,
		});

		console.log('setCollection result', data);
		return data;
	} catch (error) {
		console.error(error);
	}
};

export const setProfile = async (request) => {
	try {
		const { data } = await authApi.post('/portal/member/profile', {
			...request,
		});

		console.log('setProfile result', data);
		return data;
	} catch (error) {
		console.error(error);
	}
};

export const setCollectionProfile = async (request) => {
	try {
		const { data } = await authApi.post('/portal/collection/profile', {
			...request,
		});

		console.log('setCollectionProfile result', data);
		return data;
	} catch (error) {
		console.error(error);
	}
};
