import { makeAutoObservable, reaction, runInAction } from 'mobx';
import * as LikuApi from '../../services/apis/LikuApi';
import { Liku } from './Liku';
import * as Cookie from '../../utils/Cookie';
import MqttStore from './MqttStore';

class LikuStore {
	rootStore;

	likus = [];
	thisLiku = null;

	isHeartBeat = 0;
	heartBeat = 0;

	greetConfig = {};

	constructor(root) {
		makeAutoObservable(this);
		this.rootStore = root;

		this.mqttHandler = reaction(
			() => this.thisLiku,
			(liku) => {
				console.log('thisLiku', liku);
				if (liku) this.rootStore.mqttStore.connect(this.connectInit.bind(this));
				else this.rootStore.mqttStore.disconnect();
			},
		);
	}

	connectInit() {
		this.initSubscribes();
		this.setHeartBeatInterval();
		// MqttStore.setHeartBeatInterval(this.thisLiku?.uuid);
	}

	initSubscribes() {
		// const account = Cookie.get('token');
		// const ackTopic = `liku/${account}/ackCheck`;
		// const email = Cookie.get('email');
		// const collectionName = this.rootStore.authStore.auth.collectionName;
		const collectionName = Cookie.get('collectionName');
		const ackTopic = `liku/${collectionName}/ackCheck`;
		const heartbeatTopic = `liku/${this.thisLiku?.uuid}/heartbeat`;
		const resultTopic = `liku/${this.thisLiku?.uuid}/json/result`;
		const volumeTopic = `liku/${this.thisLiku?.uuid}/volume`;
		const motionTopic = `liku/${this.thisLiku?.uuid}/motion-status`;

		this.rootStore.mqttStore.subscribeToTopic(ackTopic);
		this.rootStore.mqttStore.subscribeToTopic(
			heartbeatTopic,
			this.setHeartBeat.bind(this),
		);
		this.rootStore.mqttStore.subscribeToTopic(
			resultTopic,
			this.jsonResultDone.bind(this),
		);
		this.rootStore.mqttStore.subscribeToTopic(
			volumeTopic,
			this.thisLiku.setVolume.bind(this),
		);
		this.rootStore.mqttStore.subscribeToTopic(
			motionTopic,
			this.thisLiku.setMotionStatus.bind(this),
		);
	}

	setHeartBeat(value) {
		if (this.isHeartBeat) {
			const currentDate = new Date();
			console.log(
				`setHeartBeat !! ${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}:${currentDate.getMilliseconds()}`,
			);

			this.heartBeat = value;
		} else {
			this.setHeartBeatInterval();
		}
	}

	setHeartBeatInterval() {
		const currentDate = new Date();
		console.log(
			`setHeartBeatInterval START !! ${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}:${currentDate.getMilliseconds()}`,
		);
		this.isHeartBeat = true;
		const heartBeat = setInterval(() => {
			// if (topic) this.heartbeat = 0;
			// else this.heartbeat += 1;
			this.heartBeat += 1;

			// const TIME_OUT = 15;
			const TIME_OUT = 150;
			if (this.heartBeat >= TIME_OUT || !this.rootStore.mqttStore.isConnected) {
				const currentDate = new Date();
				console.log(
					`heartbeat clearInterval !!! ${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}:${currentDate.getMilliseconds()}`,
				);
				this.isHeartBeat = false;
				clearInterval(heartBeat);
			}
		}, 1000);
	}

	jsonResultDone() {
		console.log('jsonResultDone');
	}

	async loadLiku() {
		const likuList = await LikuApi.getLikuAll();
		runInAction(() => {
			this.likus = [];
			likuList.forEach((liku) => this.updateLiku(liku));
		});

		// try {
		// 	if (this.likus[1]) {
		// 		this.thisLiku = this.likus[1];
		// 	}
		// } catch (error) {
		// 	console.error(error);
		// }
	}

	updateLiku(json) {
		let liku = this.likus.find((custom) => custom?.id === json?.id);
		if (!liku) {
			console.log('json', json);
			liku = new Liku(this, json);
			this.likus.push(liku);
		} else {
			liku.update(json);
		}
	}

	setThisLiku(liku = this.thisLiku) {
		if (this.thisLiku === liku) this.thisLiku = null;
		else this.thisLiku = liku;

		console.log('this.liku', this.thisLiku);
	}

	async addLiku(liku) {
		const collectionName = this.rootStore?.authStore?.auth?.collectionName;
		const data = {
			serial: liku,
			collection: { name: collectionName },
		};
		// delete userJson.id;
		const result = await LikuApi.addLiku(data);
		if (result?.reason === 'exist') {
			alert('이미 등록된 리쿠입니다. 등록된 리쿠를 삭제 후 다시 등록해주세요.');
		} else if (result?.reason === 'not found liku') {
			alert('리쿠 시리얼을 다시 확인해주세요.');
		} else {
			this.updateLiku(result);
			return true;
		}

		return false;
	}

	dispose() {
		this.mqttHandler();
	}

	async findLikuByUuid(uuid) {
		return this.likus.find((liku) => liku.uuid === uuid);
	}

	async setGreetConfig(topic, data) {
		try {
			const uuid = topic.split('/')[1];
			const liku = await LikuStore.findLikuByUuid(uuid);

			this.greetConfig[liku.serial] = data !== 'None' ? data : {};
		} catch (error) {
			console.error(error);
		}
	}

	/**
	 * 첫인사 관련 config publish입니다.
	 * @param {string} liku
	 * @param {object} data
	 */
	async setGreetConfigPub(liku, data) {
		const topic = `liku/${liku.uuid}/config`;
		await MqttStore.publishMessage(topic, data);
	}

	async getGreetConfigPub() {
		for (const liku of this.likus) {
			const uuid = liku.uuid;
			const topic = `liku/${uuid}/config/get`;
			await MqttStore.publishMessage(topic, 'get');
		}
	}

	async greetConfigSub() {
		for (const liku of this.likus) {
			const uuid = liku.uuid;
			const topic = `liku/${uuid}/config/response`;
			await MqttStore.subscribeToTopic(topic);
		}
	}
}

export default LikuStore;
