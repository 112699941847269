import styled, { css } from 'styled-components';

export const Container = styled.header`
	position: fixed;
	box-sizing: border-box;
	//position: sticky;
	z-index: 999;
	//opacity: 0.7;
	//backdrop-filter: blur(30px);

	max-width: 100vw !important;
	width: 100vw !important;
	//height: 6.5rem;
	//padding: 2rem 4.3rem;
	height: 7.5rem;
	padding: 2.5rem 4.3rem;
	box-sizing: border-box;
	// background-color: ${({ theme }) => theme.color.background};
	${({ theme }) => theme.common.container};
	background-color: white;

	margin: 0;
`;

export const Content = styled.div`
	height: 100%;
	position: relative;
	${({ theme }) => theme.common.container};
	${({ theme }) => theme.common.flex('row', 'center', 'center')};
`;

export const Left = styled.div`
	flex: 1;
	${({ theme }) => theme.common.flex('row', 'center', 'flex-start')};
	${({ theme }) => theme.common.wh('auto', '100%')};
`;
export const Center = styled.div`
	flex: 1;
	${({ theme }) => theme.common.flex('row', 'center', 'center')};
	${({ theme }) => theme.common.wh('auto', '100%')};
`;
export const Right = styled.div`
	flex: 1;
	${({ theme }) => theme.common.flex('row', 'center', 'flex-end')};
	${({ theme }) => theme.common.wh('auto', '100%')};
`;

export const Logo = styled.img`
	${({ theme }) => theme.common.wh('auto', '100%')};
	&:hover {
		cursor: pointer;
	}
`;

export const ProfileWrap = styled.div`
	gap: 0 2rem;
	${({ theme }) => theme.common.flex()};
	${({ theme }) => theme.common.wh('auto', '100%')};
`;

export const Profile = styled.button`
	aspect-ratio: 1 / 1;
	border: none;
	border-radius: 15px;
	padding: 0;
	box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16), inset 0 0 6px 0 rgba(0, 0, 0, 0.16);
	${({ theme }) => theme.common.flex()};
	${({ theme }) => theme.common.wh('auto', '100%')};
`;

export const ProfileImage = css`
	object-fit: fill;
	cursor: pointer;
	${({ theme }) => theme.common.wh('auto', '150%')};
	&:hover {
		filter: drop-shadow(2px 4px 4px ${({ theme }) => theme.color.border});
	}
`;

export const ProfileModal = styled.div`
	* {
		box-sizing: border-box;
		padding: 0;
		margin: 0;
	}
	cursor: default;
	position: absolute;
	display: ${({ open }) => (open ? 'flex' : 'none')};
	flex-direction: column;
	row-gap: 1rem;
	top: 150%;
	width: 20%;
	min-width: 100px;
	max-width: 150px;
	background-color: white;
	right: 0%;
	border: 1px solid ${({ theme }) => theme.color.regular};
	box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.08);
	border-radius: 8px;

	padding: 1rem 2rem;
`;

export const ProfileModalName = styled.div`
	& .bold {
		font-weight: bold;
	}
`;

export const LogoutButton = styled.div`
	padding: 0.5rem 0;
	margin: auto;
	cursor: pointer;
	border-radius: 2rem;
	width: 50%;
	max-width: 100px;
	border: 1px solid
		${({ theme }) => {
			return theme.color.border;
		}};
	font-size: 1rem;
	&:hover {
		border: 1px solid ${({ theme }) => theme.color.orange};
		color: ${({ theme }) => theme.color.orange};
	}
`;
