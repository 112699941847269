import styled from 'styled-components';

export const Container = styled.footer`
	//position: sticky;
	position: fixed;
	z-index: 999;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 0 0 2rem 0;
	box-sizing: border-box;

	${({ theme }) => theme.common.container};
`;

export const Content = styled.div`
	min-height: 12rem;
	max-height: 34.4rem;
	${({ theme }) => theme.common.flex('row', 'flex-end', 'center')};
	${({ theme }) => theme.common.wh('auto', '100%')};
`;

export const Left = styled.div`
	flex: 1;
	padding-right: 1.6rem;
	${({ theme }) => theme.common.flex('row', 'center', 'flex-end')};
	${({ theme }) => theme.common.wh('auto', '100%')};
`;
export const Center = styled.div`
	flex: 1;
	${({ theme }) => theme.common.flex('row', 'center', 'center')};
`;
export const Right = styled.div`
	flex: 1;
	padding-left: 1.6rem;
	${({ theme }) => theme.common.flex('row', 'center', 'flex-start')};
	${({ theme }) => theme.common.wh('100%', '100%')};
`;
