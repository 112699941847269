import styled, { css } from 'styled-components';

export const Container = styled.div`
	border-radius: 15px;
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
	overflow: hidden;
	transition: all 1s ease-in-out;
	max-width: 100%;
	flex-grow: ${({ click }) => (!click ? 'auto' : '1')};
	${({ theme }) => theme.common.flex('column', 'center', 'space-between')};
`;

export const Context = styled.div`
	border-radius: 15px 15px 0 0;
	background-color: #ffffff;
	overflow: hidden;
	transition: all 1s ease-in-out;
	box-sizing: border-box;
	//max-height: 27.4rem;
	// flex-grow: ${({ click }) => (!click ? 'auto' : '1')};
	max-height: ${({ click }) => (!click ? '0rem' : '27.4rem')};

	padding: ${({ click }) => (!click ? '0rem' : '3rem 2.85rem 1.9rem')};
	${({ theme }) => theme.common.flex('column', 'flex-start', 'space-between')};
	${({ theme }) => theme.common.wh('100%')};
`;

export const ContextTop = styled.div`
	${({ theme }) => theme.common.wh('max-content', '1.7rem')};
	${({ theme }) => theme.common.flex('row', 'flex-end', 'flex-start')};
`;

export const ContextTitle = styled.div`
	font-size: 1.2rem;
	color: #707070;
	font-family: HelveticaNeue-Bold;
	font-weight: bold;
`;
export const ContextInfo = styled.div`
	font-size: 1rem;
	color: #9a9a9a;
	font-family: HelveticaNeue-Regular;
	margin: 0 1.3rem;
`;

export const ContextWrap = styled.div`
	box-sizing: border-box;
	overflow-y: auto;
	padding-top: 1.3rem;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 0 clamp(1.1rem, 4.95%, 2.5rem);
	place-content: space-between;
	${({ theme }) => theme.common.wh('100%')};
	${({ click }) =>
		!click &&
		css`
			-ms-overflow-style: none; /* IE and Edge */
			scrollbar-width: none; /* Firefox */
			&::-webkit-scrollbar {
				display: none; /* Chrome, Safari, Opera*/
			}
		`};
`;

export const Menus = styled.div`
	display: flex;
	flex: 1;
	flex-flow: row nowrap;
	opacity: 0.95;
	padding: 1.1rem;
	box-sizing: border-box;
	gap: 1.1rem;
	overflow: hidden;
	transition: all 1s ease-in-out;
	${({ theme }) => theme.common.wh('100%')};
	&.Dock {
		justify-content: flex-start;
		background-color: ${({ click }) => (click ? '#fed34b' : '#ffffff')};
	}
	&.Alarm {
		justify-content: flex-end;
		box-shadow: 0 -2px 8px 0 rgba(0, 0, 0, 0.1);
		background-color: #fff;
	}
	&.Small {
		min-width: 6rem;
		max-height: 6rem;
	}
	&.Big {
		min-width: 7rem;
		max-height: 7rem;
	}
`;
