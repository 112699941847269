import React from 'react';
import { observer } from 'mobx-react';

import * as S from './Footer.style';

import FooterAlarm from './FooterAlarm';
import FooterLiku from './liku/FooterLiku';
import FooterDock from './FooterDock';

const Footer = observer(() => {
	return (
		<S.Container>
			<S.Content>
				<S.Left>
					<FooterAlarm />
				</S.Left>
				<S.Center>
					<FooterLiku />
				</S.Center>
				<S.Right>
					<FooterDock />
				</S.Right>
			</S.Content>
		</S.Container>
	);
});

export default Footer;
