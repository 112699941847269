import React from 'react';
import { observer } from 'mobx-react';

import * as S from './FooterLiku.style';

import FooterLikuConnect from './FooterLikuConnect';
import FooterLikuUnConnect from './FooterLikuUnConnect';
// import FooterLikuMusic from './FooterLikuMusic';

import { RootStoreContext } from '../../../../App';

const FooterLiku = observer(() => {
	const store = React.useContext(RootStoreContext);

	return (
		<S.Container thisLiku={store.likuStore.thisLiku}>
			{store.likuStore.thisLiku ? (
				<FooterLikuConnect />
			) : (
				<FooterLikuUnConnect />
			)}
			{/*<FooterLikuMusic />*/}
		</S.Container>
	);
});

export default FooterLiku;
